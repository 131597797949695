import React from 'react';
import { createGlobalStyle } from "styled-components"
import {
  CabinBoldWoff2,
  CabinRegularWoff2,
  LagiWoff2,
  RalewayMediumWoff2,
  RalewayRegularWoff2,
  RalewayThinWoff2
} from "./src/styles/fontsLoader"

const fontFace = (fontFamily, woff2) => {
  return `
    @font-face {
      font-display: fallback;
      font-family: '${fontFamily}';
      src: url(${woff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `;
};

const fontStyles = [
  fontFace('Cabin Regular', CabinRegularWoff2),
  fontFace('Cabin Bold', CabinBoldWoff2),
  fontFace('Raleway Thin', RalewayThinWoff2),
  fontFace('Raleway Regular', RalewayRegularWoff2),
  fontFace('Raleway Medium', RalewayMediumWoff2),
  fontFace('Lagi Regular', LagiWoff2),
].join('\n');

const GlobalFontStyle = createGlobalStyle`
  ${fontStyles}
`;

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalFontStyle />
      {element}
    </>
  );
};


export const onServiceWorkerUpdateReady = () => window.location.reload();
