import CabinRegularWoff2 from "../../src/resources/fonts/cabin-regular.woff2";
import CabinBoldWoff2 from "../../src/resources/fonts/cabin-bold.woff2";
import RalewayThinWoff2 from "../../src/resources/fonts/raleway-thin.woff2";
import RalewayRegularWoff2 from "../../src/resources/fonts/raleway-regular.woff2";
import RalewayMediumWoff2 from "../../src/resources/fonts/raleway-medium.woff2";
import LagiWoff2 from "../../src/resources/fonts/L4Gdev-Regular.ttf";

export {
  CabinRegularWoff2,
  CabinBoldWoff2,
  RalewayThinWoff2,
  RalewayRegularWoff2,
  RalewayMediumWoff2,
  LagiWoff2,
};
